import { PAYMENTS_SECTIONS_ROUTES } from '@/models/app-internal/permissions.model';
import { RouteComponent, RouteRecordRaw } from 'vue-router';

export const presetSettingsRoutes: RouteRecordRaw[] = [
  {
    name: PAYMENTS_SECTIONS_ROUTES.PRESET_SETTINGS,
    path: `/${PAYMENTS_SECTIONS_ROUTES.PRESET_SETTINGS}`,
    component: async(): Promise<RouteComponent> =>
      await import('@/layouts/preset-settings-page/preset-settings-page.vue'),
  },
];
