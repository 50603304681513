import type { CurrencyFormValue, SettingsForm } from '@/models/preset-settings/settings-screen.model';
import type { CashierConfig } from '@/models/preset-settings/preset-settings.model';
import type { CurrencyItem } from '@/models/dictionary/currency-list.model';

export const getMappedPayload = (data: Record<string, CurrencyFormValue>, country: string): CashierConfig => {
  // Извлекаем все валюты (ключи объекта)
  const currencyList = Object.keys(data);

  // Формируем defaultAmount
  const defaultAmount = {
    depositList: currencyList.map((currency) => ({
      currency,
      amount: Number(data[currency].data.deposit),
    })),
    withdrawalList: currencyList.map((currency) => ({
      currency,
      amount: Number(data[currency].data.withdrawal),
    })),
  };

  // Формируем suggestedAmount
  // Для этого берём все ключи, начинающиеся на "preset", и превращаем их значения в числа.
  const suggestedAmount = {
    depositList: currencyList.map((currency) => {
      const presetData = data[currency].data;
      const presetKeys = Object.keys(presetData)
        .filter((key) => key.startsWith('preset') && presetData[key]);

      return {
        currency,
        presetAmountList: presetKeys.map((presetKey) => Number(presetData[presetKey])),
      };
    }),
    withdrawalList: [],
    defaultList: [50, 100, 200, 500], // требование бэка
  };

  return {
    country,
    defaultAmount,
    suggestedAmount,
  };
};

export const getMappedFormDataFromConfig =
  (config: CashierConfig | null): Record<CurrencyItem['code'], SettingsForm> | null => {
    if (!config) return null;

    const data = {};

    config.defaultAmount?.depositList.forEach(item => {
      data[item.currency] = { deposit: item.amount };
    });

    config.defaultAmount?.withdrawalList.forEach(item => {
      if (data[item.currency]) {
        data[item.currency].withdrawal = item.amount;
      } else {
        data[item.currency] = { withdrawal: item.amount };
      }
    });

    config.suggestedAmount?.depositList.forEach(item => {
      item.presetAmountList.forEach((amount, index) => {
        const presetCount = `preset${index + 1}`;

        if (data[item.currency]) {
          data[item.currency][presetCount] = amount;
        } else {
          data[item.currency] = { [presetCount]: amount };
        }
      });
    });

    return data;
  };
