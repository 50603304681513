import { defineComponent } from 'vue';
import { Right } from '@element-plus/icons-vue';
import LogsPageDifference from '@/layouts/logs-page/logs-page-difference/logs-page-difference.vue';

export default defineComponent({
  name: 'LogsPageDifferenceDialog',

  components: { LogsPageDifference, Right },

  props: {
    parameterItem: {
      type: Object as () => Record<string, string>,
      required: true,
    },
  },
});
