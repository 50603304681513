import type { ComponentPublicInstance } from 'vue';
import type { FormRules, FormInstance } from 'element-plus';

export type SettingsForm = {
  deposit: string;
  withdrawal: string;
  preset1: string;
  preset2: string;
  preset3: string;
  preset4: string;
  preset5: string;
}

export type CurrencyFormValue = {
  customRef: CurrencyFormInstance | null;
  data: SettingsForm;
  switchers: Record<string, boolean>;
};

export const initSettingsFormSwitchers = {
  preset4: false,
  preset5: false,
} as const;

export const initSettingsForm: SettingsForm = {
  deposit: '',
  withdrawal: '',
  preset1: '',
  preset2: '',
  preset3: '',
  preset4: '',
  preset5: '',
} as const;

export const changeableFields = ['preset4', 'preset5'];

export type CurrencyFormInstance = ComponentPublicInstance<{
  currencyFormRef: FormInstance;
  model: SettingsForm;
  rules: FormRules<SettingsForm>;
  switchers: Record<string, boolean>;
}>;
