import { CashierConfig } from '@/models/preset-settings/preset-settings.model';
import { defineStore } from 'pinia';
import { api } from '@/services/http.service';
import { ElNotification } from 'element-plus';

type PresetSettingsStore = {
  isCashierConfigListLoading: boolean;
  isCurrencyFormChanged: boolean;
  isCreateCashierConfigLoading: boolean;
  cashierConfigList: CashierConfig[];
  selectedCountryConfig: {
    country: string;
    config: CashierConfig | null;
  };
}

export const usePresetSettingsStore = defineStore({
  id: 'PresetSettingsStore',
  state: (): PresetSettingsStore => ({
    isCashierConfigListLoading: false,
    isCreateCashierConfigLoading: false,
    cashierConfigList: [],
    isCurrencyFormChanged: false,
    selectedCountryConfig: {
      country: '',
      config: null,
    },
  }),
  getters: {
    getCurrencyListFromConfig(): (config: CashierConfig | null) => string[] {
      return (config) => {
        return config
          ? Array.from(
            new Set(
              [
                ...(config.defaultAmount?.depositList ?? []).map(item => item.currency),
                ...(config.defaultAmount?.withdrawalList ?? []).map(item => item.currency),
                ...(config.suggestedAmount?.depositList ?? []).map(item => item.currency),
                ...(config.suggestedAmount?.withdrawalList ?? []).map(item => item.currency),
              ],
            ))
          : [];
      };
    },
  },
  actions: {
    setCashierConfigLoading(isLoading: boolean): void {
      this.isCashierConfigListLoading = isLoading;
    },
    setCreateCashierConfigLoading(isLoading: boolean): void {
      this.isCreateCashierConfigLoading = isLoading;
    },
    getCashierConfigList(): Promise<void> {
      this.setCashierConfigLoading(true);

      return api.presetSettings.getCashierConfigListRequest.perform()
        .then(data => {
          this.cashierConfigList = data;
        })
        .finally(() => {
          this.setCashierConfigLoading(false);
        });
    },
    async getCashierConfig(country: string): Promise<void> {
      this.setCashierConfigLoading(true);

      return await api.presetSettings.getCashierConfigRequest.perform(country)
        .then(data => {
          this.selectedCountryConfig.config = data;
        })
        .finally(() => {
          this.setCashierConfigLoading(false);
        });
    },
    async createCashierConfig(country: string, countryName: string) {
      this.setCreateCashierConfigLoading(true);

      return await api.presetSettings.createCashierConfig.perform(country)
        .then(() => {
          this.setSelectedCountry(country);
          ElNotification({
            title: 'Successful',
            message: `${countryName} cashier configuration successfully created.`,
            type: 'success',
          });
        })
        .finally(() => {
          this.setCreateCashierConfigLoading(false);
        });
    },
    async updateCashierConfig(config: CashierConfig, countryName: string) {
      this.setCashierConfigLoading(true);

      return await api.presetSettings.updateCashierConfig.perform(config.country, config)
        .then(() => {
          ElNotification({
            title: 'Successful',
            message: `${countryName} cashier configuration successfully saved.`,
            type: 'success',
          });
        })
        .finally(() => {
          this.setCashierConfigLoading(false);
        });
    },
    setSelectedCountry(country: string) {
      this.selectedCountryConfig.country = country;
    },

    setIsCurrencyFormChanged(isChanged: boolean): void {
      this.isCurrencyFormChanged = isChanged;
    },
  },
});
