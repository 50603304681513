import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "logs-page-parameter-list",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clickParameterRow')))
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logsParameterRow, (value, key) => {
      return (_openBlock(), _createElementBlock("div", {
        key: key,
        class: "logs-page-parameter-list__item"
      }, [
        _createElementVNode("div", null, _toDisplayString(key) + ": " + _toDisplayString(value), 1 /* TEXT */)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}