import { storeToRefs } from 'pinia';
import { defineComponent } from 'vue';
import { useTwoFactorStore } from '@/store/two-factor-dialog.store';
import { Filter, Refresh, InfoFilled, Download } from '@element-plus/icons-vue';
import { useLogsListStore } from '@/store/logs-page/logs-list.store';
import { useLogListFilterStore } from '@/store/logs-page/log-list-filter.store';
import { GetFilteredAdminEmailList } from '@/models/logs-page/logs-list.model';
import { usePermissionsStore } from '@/store/permissions.store';
import { useLogListReportStore } from '@/store/logs-page/log-list-report.store';

export default defineComponent({
  name: 'LogsPageFilters',

  setup() {
    const icons = {
      Refresh,
      Filter,
      InfoFilled,
      Download,
    };

    const twoFactorStore = useTwoFactorStore();

    const { isLogListReportLoading } = storeToRefs(useLogListReportStore());

    const { isLogsDownloadCvsAllowed } = usePermissionsStore();

    const { openTwoFactorDialog } = twoFactorStore;

    const logsListStore = useLogsListStore();

    const {
      isLogsListLoading,
      logsFilterList,
    } = storeToRefs(logsListStore);

    const logsListFilterStore = useLogListFilterStore();

    const { submitFilter, resetFilter } = logsListFilterStore;

    const { filterFormData } = storeToRefs(logsListFilterStore);

    const getFilteredAdminEmailList: GetFilteredAdminEmailList = (query, callback) => {
      const { adminEmailFilterList } = logsFilterList.value;

      const filteredEmails = adminEmailFilterList.filter((email) =>
        email.value.toLowerCase().includes(query.toLowerCase()));

      callback(filteredEmails);
    };

    return {
      isLogListReportLoading,
      isLogsListLoading,
      isLogsDownloadCvsAllowed,
      submitFilter,
      resetFilter,
      filterFormData,
      getFilteredAdminEmailList,
      icons,
      logsFilterList,
      openTwoFactorDialog,
    };
  },
});
