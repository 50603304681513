import { defineStore } from 'pinia';
import { clearObjIncludesFields } from '@/utils/form.utils';
import { api } from '@/services/http.service';
import { ElNotification } from 'element-plus';
import { useLogListFilterStore } from '@/store/logs-page/log-list-filter.store';
import { downloadFile } from '@/utils/downlod-file.util';
import { DEFAULT_ITEMS_PER_PAGE_LIST } from '@/models/constants';

type LogListReportStore = {
  isLogListReportLoading: boolean;
}

export const useLogListReportStore = defineStore({
  id: 'LogsListReportStore',
  state: (): LogListReportStore => ({
    isLogListReportLoading: false,
  }),

  actions: {
    setLogListReportLoading(isLoading: boolean): void {
      this.isLogListReportLoading = isLoading;
    },

    getLogListReport(): Promise<void> {
      this.setLogListReportLoading(true);

      const { filterFormData } = useLogListFilterStore();

      const requestPayload = {
        limit: DEFAULT_ITEMS_PER_PAGE_LIST[0],
        filterList: clearObjIncludesFields(filterFormData),
      };

      return api.logs.getLogListReportRequest.perform(requestPayload)
        .then((data) => {
          downloadFile(
            `logs-report-${dayjs().format('YYYY-MM-DD HH:mm:ss')}.csv`,
            'data:text/csv;charset=UTF-8,' + encodeURIComponent(data.fileData),
          );
        })
        .catch((error) => {
          ElNotification({
            title: 'Error',
            message: error,
            type: 'error',
          });
        })
        .finally(() => {
          this.setLogListReportLoading(false);
        });
    },
  },
});
