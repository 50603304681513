import { defineComponent, computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { usePermissionsStore } from '@/store/permissions.store';
import { usePresetSettingsStore } from '@/store/preset-settings/preset-settings.store';
import { Plus, Refresh, Setting } from '@element-plus/icons-vue';
import {
  type CashierConfig,
  cashierConfigListColumns,
  PresetSettingsColumnProperties,
} from '@/models/preset-settings/preset-settings.model';
import type { ColumnFilterItem } from '@/models/column-filter/column-filter.model';
import { useCountryListStore } from '@/store/dictionary/countries.store';
import { PresetSettingsButtonName } from '@/models/preset-settings/preset-settings.model';
import { PRESET_SETTINGS_PERMISSIONS } from '@/models/app-internal/permissions.model';
import CurrencyTable from '@/layouts/preset-settings-page/currency-table/currency-table.vue';

const icons = {
  Plus,
  Refresh,
  Setting,
};

export default defineComponent({
  name: 'PresetSettingsPage',
  components: {
    CurrencyTable,
  },
  emits: ['show-add-country-dialog'],
  setup() {
    const { presetSettingsPermissions } = usePermissionsStore();
    const { countryList } = storeToRefs(useCountryListStore());

    const presetSettingsStore = usePresetSettingsStore();
    const { getCurrencyListFromConfig, getCashierConfigList, setSelectedCountry } = presetSettingsStore;
    const { cashierConfigList, isCashierConfigListLoading } = storeToRefs(presetSettingsStore);

    const getCountryName = (code: string): string | undefined =>
      countryList.value.find(country => country.code === code)?.name;

    const isChangeCountrySettingsAvailable = computed<boolean>(() =>
      presetSettingsPermissions[PRESET_SETTINGS_PERMISSIONS.CREATE_CASHIER_CONFIG] &&
    presetSettingsPermissions[PRESET_SETTINGS_PERMISSIONS.UPDATE_CASHIER_CONFIG]);

    const getRowClassName: ({ row }: Record<'row', CashierConfig>) => string =
      ({ row }) => !getCurrencyListFromConfig(row).length ? 'no-expand' : '';

    const getColumnSortable: (column: ColumnFilterItem) => boolean =
      (column) => column.value === PresetSettingsColumnProperties.COUNTRY &&
      cashierConfigList.value.length > 1;

    const getColumnType: (column: ColumnFilterItem) => string | false =
      (column) => column.value === PresetSettingsColumnProperties.EXPAND && 'expand';

    const getCurrencyTableVisibility: (row: CashierConfig, column: ColumnFilterItem) => boolean =
      (row, column) => {
        const currencyListFromConfig = presetSettingsStore.getCurrencyListFromConfig(row);

        return column.value === PresetSettingsColumnProperties.EXPAND && Boolean(currencyListFromConfig.length);
      };

    onMounted(() => {
      getCashierConfigList();
    });

    return {
      icons,
      getColumnType,
      getCountryName,
      getRowClassName,
      getColumnSortable,
      PresetSettingsButtonName,
      cashierConfigListColumns,
      getCurrencyTableVisibility,
      PresetSettingsColumnProperties,
      isChangeCountrySettingsAvailable,
      setSelectedCountry,
      getCashierConfigList,
      getCurrencyListFromConfig,
      cashierConfigList,
      isCashierConfigListLoading,
    };
  },
});
