import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "logs-page-difference" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_empty = _resolveComponent("el-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.currentParameter)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("pre", null, _toDisplayString(_ctx.formatToJsonString(_ctx.currentParameter)), 1 /* TEXT */)
        ]))
      : (_openBlock(), _createBlock(_component_el_empty, {
          key: 1,
          description: "No data"
        }))
  ]))
}