import { defineStore } from 'pinia';
import { LogsFilterModel } from '@/models/logs-page/logs-list.model';
import { useLogsListStore } from '@/store/logs-page/logs-list.store';
import { clearObj } from '@/utils/form.utils';

type LogsListFilterStore = {
  filterFormData: LogsFilterModel;
  isFilterActive: boolean;
  isFilterVisible: boolean;
  activeFiltersCount: number;
}

export const useLogListFilterStore = defineStore({
  id: 'LogListFilterStore',

  state: (): LogsListFilterStore => ({
    filterFormData: {},
    isFilterActive: false,
    isFilterVisible: false,
    activeFiltersCount: 0,
  }),

  actions: {
    setFilterFormData(filterData: LogsFilterModel): void {
      this.filterFormData = { ...filterData };
      this.activeFiltersCount = Object.keys(this.filterFormData).length;
    },

    clearFilterFormData(): void {
      this.filterFormData = {};
      this.activeFiltersCount = 0;
    },

    resetFilter(): void {
      const { getLogsList, refreshPagination } = useLogsListStore();

      this.clearFilterFormData();

      refreshPagination();

      getLogsList();
    },

    submitFilter(): void {
      const { getLogsList, refreshPagination } = useLogsListStore();

      this.setFilterFormData(clearObj(this.filterFormData));

      refreshPagination();

      getLogsList();
    },
  },
});
