import { defineComponent, computed, PropType } from 'vue';
import {
  type CurrencySettings,
  type CashierActionType,
  type InnerTableRowMethodProps,
  currencySettingsColumns,
  CurrencyTableColumnProperties,
} from '@/models/preset-settings/currency-table.model';
import type { CurrencyItem } from '@/models/dictionary/currency-list.model';
import type {
  CashierConfig,
  CashierConfigDefaultAmountItem,
  CashierConfigSuggestedAmountItem,
} from '@/models/preset-settings/preset-settings.model';

export default defineComponent({
  name: 'CurrencyTable',
  props: {
    row: {
      type: Object as PropType<CashierConfig>,
      required: true,
    },
  },
  setup(props) {
    // колонки таблицы, где нужно схлопнуть строки
    const COLLAPSE_COLUMN_INDEXES = [0, 1, 4];

    // Метод из доки для el-table для "схлапывания строк в одну" в конкретных колонках
    const spanMethod = ({ rowIndex, columnIndex }: InnerTableRowMethodProps) => {
      if (COLLAPSE_COLUMN_INDEXES.includes(columnIndex)) {
        return rowIndex % 2 === 0
          ? [2, 1]
          : [0, 0];
      }
    };

    const currencySettingsList = computed<CurrencySettings[]>(() => {
      const config = props.row;
      const map: Record<CurrencyItem['code'], CurrencySettings> = {};

      const ensureEntry = (currency: CurrencyItem['code'], type: CashierActionType): CurrencySettings => {
        const key = `${type}-${currency}`;
        if (!map[key]) {
          map[key] = { currency, type };
        }

        return map[key];
      };

      const addDefaultAmounts = (
        сashierConfigDefaultAmountItem: CashierConfigDefaultAmountItem[] | undefined,
        type: CashierActionType,
      ): void => {
        сashierConfigDefaultAmountItem?.forEach(({ currency, amount }) => {
          ensureEntry(currency, type).defaultAmount = amount;
        });
      };

      const addSuggestedAmounts = (
        cashierConfigSuggestedAmountItem: CashierConfigSuggestedAmountItem[] | undefined,
        type: CashierActionType,
      ): void => {
        cashierConfigSuggestedAmountItem?.forEach(({ currency, presetAmountList }) => {
          ensureEntry(currency, type).presetAmountList = presetAmountList;
        });
      };

      // Добавляем данные по defaultAmount
      addDefaultAmounts(config.defaultAmount?.depositList, 'deposit');
      addDefaultAmounts(config.defaultAmount?.withdrawalList, 'withdrawal');

      // Добавляем данные по suggestedAmount
      addSuggestedAmounts(config.suggestedAmount?.depositList, 'deposit');
      addSuggestedAmounts(config.suggestedAmount?.withdrawalList, 'withdrawal');

      const currencySettingListMappedValueList: CurrencySettings[] = Object.values(map);

      // Собираем все валюты, которые есть в map
      const allCurrencyListFromMappedValueList = currencySettingListMappedValueList
        .map(currencySettings => currencySettings.currency);

      // Гарантируем, что для каждой валюты есть оба типа (deposit и withdrawal)
      allCurrencyListFromMappedValueList.forEach(currency => {
        ensureEntry(currency, 'deposit');
        ensureEntry(currency, 'withdrawal');
      });

      return currencySettingListMappedValueList
        .map(originalItem => {
          const item = { ...originalItem };

          // Устанавливаем defaultAmount и presetAmountList, если они не заданы
          item.defaultAmount ??= null;
          item.presetAmountList ??= [];

          return item;
        })
        .sort((a, b) => a.currency.localeCompare(b.currency));
    });

    return {
      spanMethod,
      currencySettingsList,
      currencySettingsColumns,
      CurrencyTableColumnProperties,
    };
  },
});
