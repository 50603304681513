import { DEFAULT_ITEMS_PER_PAGE_LIST } from '@/models/constants';
import { LogsFilterList, LogsItem } from '@/models/logs-page/logs-list.model';
import { defineStore } from 'pinia';
import { useLogListFilterStore } from '@/store/logs-page/log-list-filter.store';
import { clearObjIncludesFields } from '@/utils/form.utils';
import { formatDatesInPayload } from '@/utils/date-time.format.util';
import { api } from '@/services/http.service';
import { ElNotification } from 'element-plus';
import { ResponseHeaders } from '@/models/app-internal/headers.model';

type LogsListStore = {
  isLogsListLoading: boolean;
  logList: LogsItem[];
  logsFilterList: LogsFilterList;
  limit: number;
  offset: number;
  totalPages: number;
  currentPage: number;
  pageSizes: number[];
  isDialogVisible: boolean;
  selectedParameterItem: Record<string, string>;
}

export const useLogsListStore = defineStore({
  id: 'LogsListStore',

  state: (): LogsListStore => ({
    isLogsListLoading: false,
    logList: [],
    logsFilterList: {
      adminEmailFilterList: [],
      actionFilterList: [],
      paymentSystemFilterList: [],
      geoFilterList: [],
    },
    limit: DEFAULT_ITEMS_PER_PAGE_LIST[0],
    pageSizes: [50, 100],
    totalPages: 0,
    offset: 0,
    currentPage: 1,
    isDialogVisible: false,
    selectedParameterItem: {},
  }),

  getters: {
    logsListWithFormattedDates(state) {
      return state.logList.map(challenge => formatDatesInPayload({
        payload: challenge,
        unixToString: true,
        includeTime: true,
      }));
    },
  },

  actions: {
    setLogsListLoading(isLoading: boolean): void {
      this.isLogsListLoading = isLoading;
    },

    setDialogVisibility(isVisible: boolean): void {
      this.isDialogVisible = isVisible;
    },

    setSelectedParameterItem(parameterItem: Record<string, string>): void {
      this.selectedParameterItem = parameterItem;
    },

    setLimit(limit: number): void {
      this.limit = limit;
    },

    deserializeJsonValue(logsItem: LogsItem, key: string) {
      return logsItem[key] ? JSON.parse(logsItem[key]) : null;
    },

    mappedFields(logItems: LogsItem[]): LogsItem[] {
      return logItems.map((logsItem: LogsItem) => {
        const currentParameterList = this.deserializeJsonValue({ ...logsItem }, 'currentParameterList');
        const formerParameterList = this.deserializeJsonValue({ ...logsItem }, 'formerParameterList');

        return { ...logsItem, currentParameterList, formerParameterList };
      });
    },

    refreshPagination(): void {
      this.offset = 0;
      this.currentPage = 1;
    },

    handleSizeChange(size: number): void {
      this.limit = size;
      this.offset = 0;
      this.currentPage = 0;

      this.getLogsList();
    },

    handleCurrentChange(pageNumber: number): void {
      this.currentPage = pageNumber;
      this.offset = (pageNumber - 1) * this.limit;

      this.getLogsList();
    },

    getLogsList(): Promise<LogsItem[] | void> {
      this.setLogsListLoading(true);

      const { filterFormData } = useLogListFilterStore();

      const requestPayload = {
        limit: this.limit,
        offset: this.offset,
        filterList: clearObjIncludesFields(filterFormData),
      };

      return api.logs.getLogListRequest.perform(requestPayload)
        .then(({ data, headers }) => {
          this.logList = this.mappedFields(data);

          this.totalPages = parseInt(headers[ResponseHeaders.LIST_ITEMS_COUNT_HEADER]);
        })
        .catch((error) => {
          ElNotification({
            title: 'Error',
            message: error,
            type: 'error',
          });
        })
        .finally(() => {
          this.setLogsListLoading(false);
        });
    },

    getFilterOptions() {
      return api.logs.getLogFilterListRequest.perform()
        .then(({ data }) => {
          this.logsFilterList = data;
        })
        .catch((error) => {
          ElNotification({
            title: 'Error',
            message: error,
            type: 'error',
          });
        })
        .finally(() => {
          this.setLogsListLoading(false);
        });
    },
  },
});
