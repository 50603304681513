import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "preset-settings-page__currency-form__row" }
const _hoisted_2 = { class: "preset-settings-page__currency-form__row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "currencyFormRef",
    inline: "",
    "label-position": "top",
    class: "preset-settings-page__currency-form",
    "hide-required-asterisk": "",
    model: _ctx.settingsForm,
    rules: _ctx.rules,
    onChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('onChangeCurrencyForm')))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_form_item, {
          label: "Default deposit amount",
          prop: "deposit"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.settingsForm.deposit,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.settingsForm.deposit) = $event)),
              placeholder: "Amount",
              type: "number"
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_form_item, {
          label: "Default withdrawal amount",
          prop: "withdrawal"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.settingsForm.withdrawal,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.settingsForm.withdrawal) = $event)),
              placeholder: "Amount",
              type: "number"
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_form_item, {
          label: "1 preset",
          prop: "preset1"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.settingsForm.preset1,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.settingsForm.preset1) = $event)),
              placeholder: "Amount",
              type: "number"
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_form_item, {
          label: "2 preset",
          prop: "preset2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.settingsForm.preset2,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.settingsForm.preset2) = $event)),
              placeholder: "Amount",
              type: "number"
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_form_item, {
          label: "3 preset",
          prop: "preset3"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.settingsForm.preset3,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.settingsForm.preset3) = $event)),
              placeholder: "Amount",
              type: "number"
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_form_item, { prop: "preset4" }, {
          label: _withCtx(() => [
            _createVNode(_component_el_switch, {
              modelValue: _ctx.switchers.preset4,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.switchers.preset4) = $event)),
              size: "small"
            }, null, 8 /* PROPS */, ["modelValue"]),
            _createVNode(_component_el_text, null, {
              default: _withCtx(() => [
                _createTextVNode("4 preset")
              ]),
              _: 1 /* STABLE */
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.settingsForm.preset4,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.settingsForm.preset4) = $event)),
              disabled: !_ctx.switchers.preset4,
              placeholder: "Amount",
              type: "number"
            }, null, 8 /* PROPS */, ["modelValue", "disabled"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_el_form_item, { prop: "preset5" }, {
          label: _withCtx(() => [
            _createVNode(_component_el_switch, {
              modelValue: _ctx.switchers.preset5,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.switchers.preset5) = $event)),
              size: "small"
            }, null, 8 /* PROPS */, ["modelValue"]),
            _createVNode(_component_el_text, null, {
              default: _withCtx(() => [
                _createTextVNode("5 preset")
              ]),
              _: 1 /* STABLE */
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.settingsForm.preset5,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.settingsForm.preset5) = $event)),
              disabled: !_ctx.switchers.preset5,
              placeholder: "Amount",
              type: "number"
            }, null, 8 /* PROPS */, ["modelValue", "disabled"])
          ]),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["model", "rules"]))
}