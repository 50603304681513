import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import type { CashierConfig } from '@/models/preset-settings/preset-settings.model';

export default class GetCashierConfigListRequest extends ApiResourceMethod<CashierConfig[]> {
  public perform(): Promise<CashierConfig[]> {
    return this.performer.requestWithMapper(
      {},
      (response: BaseResponse<CashierConfig[]>) => response.data,
    );
  }
}
