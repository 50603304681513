export enum PAYMENTS_SECTIONS_ROUTES {
  PS_MANAGEMENT = 'ps-management',
  TRANSACTIONS = 'transactions',
  PRESET_SETTINGS = 'preset-settings',
  LOGS = 'logs',
}

export enum PS_MANAGEMENT_PERMISSIONS {
  // ====================================
  // === GEO PAYMENT SYSTEMS SETTINGS ===
  // ====================================
  // запрос списка платежек для конкретной страны (выбранное значение geo в селекте)
  GET_GEO_PAYMENT_METHOD_LIST = 'backofficeapi_get_billing_payment_method_settings_list',
  // Следующая ручка — треш, мы от нее откажемся в пользу новой ручки
  // обновление ЦЕЛИКОМ ВСЕГО списка платежек для конкретной страны
  // используется также и для обновления одной платежки, !!! НО НУЖНО ВСЁ РАВНО ОТПРАВЛЯТЬ ВЕСЬ СПИСОК !!!
  // бэк напишет новую ручку PATCH /billingProvider/paymentMethodSettings/{paymentMethodSettingsId}
  // которая позволит бновлять данные для одной конкретной платежки (для конкретного гео) по её айдишнику
  // текущую ручку оставим только для изменения порядка следования платежек в списке с обновленным контрактом
  UPDATE_GEO_PAYMENT_METHOD_LIST = 'backofficeapi_save_billing_payment_method_settings_list',
  // // обновление полей depositEnabled и withdrawEnabled платежки для конкретной страны
  UPDATE_GEO_PAYMENT_METHOD_DEPOSIT_AND_WITHDRAW = 'backofficeapi_switch_billing_payment_method_settings_operation',
  // создание платежки для конкретной страны
  CREATE_GEO_PAYMENT_METHOD = 'backofficeapi_create_billing_payment_method_settings',
  // удаление ОДНОЙ (не списка, а именно одной — ошибка в названии пермишена) платежки для конкретной страны
  DELETE_GEO_PAYMENT_METHOD = 'backofficeapi_delete_billing_payment_method_settings_list',
  // обновление настроек депозит-лимитов для платежек конкретной страны
  UPDATE_GEO_PAYMENT_METHOD_DEPOSIT_LIMITS = 'backofficeapi_save_billing_payment_method_deposit_limit',
  // запрос настроек депозит-лимитов для платежек конкретной страны
  GET_GEO_PAYMENT_METHOD_DEPOSIT_LIMITS = 'backofficeapi_get_billing_payment_method_deposit_limit',
  // запрос списка настроек депозит-лимитов для платежек конкретной страны
  GET_GEO_PAYMENT_METHOD_DEPOSIT_LIMITS_LIST = 'backofficeapi_get_billing_payment_method_deposit_limit_list',
  // обновление данных гео-платежки по айдишнику
  UPDATE_GEO_PAYMENT_METHOD = 'backofficeapi_update_billing_payment_method_settings_list',

  // ================================
  // === PAYMENT SYSTEMS GEO LIST ===
  // ================================
  // запрос списка гео для селекта
  GET_PAYMENT_METHOD_GEO_LIST = 'backofficeapi_get_payment_method_geo_list',
  // добавление гео в селект
  ADD_PAYMENT_METHOD_GEO = 'backofficeapi_add_payment_method_geo',

  // =======================================
  // === GLOBAL PAYMENT SYSTEMS SETTINGS ===
  // =======================================
  // запрос списка платежек для всех стран
  GET_GLOBAL_PAYMENT_METHOD_LIST = 'backofficeapi_get_billing_payment_method_list',
  // создание платежки для всех стран
  CREATE_GLOBAL_PAYMENT_METHOD = 'backofficeapi_create_billing_payment_method',
  // удаление платежки для всех стран
  DELETE_GLOBAL_PAYMENT_METHOD = 'backofficeapi_delete_billing_payment_method',
  // запрос платежки для всех стран по айдишнику
  GET_GLOBAL_PAYMENT_METHOD = 'backofficeapi_get_billing_payment_method',
  // обновление платежки для всех стран по айдишнику
  UPDATE_GLOBAL_PAYMENT_METHOD = 'backofficeapi_update_billing_payment_method',
  // обновление полей depositEnabled и withdrawEnabled платежки для всех стран
  UPDATE_GLOBAL_PAYMENT_METHOD_DEPOSIT_AND_WITHDRAW = 'backofficeapi_switch_billing_payment_method_operation',
}

export enum TRANSACTIONS_PERMISSIONS {
  TRANSACTION_MODERATE = 'backofficeapi_billing_transaction_moderate',
  GET_PAYMENT_LIST = 'backofficeapi_whitelabel_get_payment_list',
  GET_PAYMENT_LIST_TOTAL = 'backofficeapi_whitelabel_get_payment_list_total',
  GET_PAYMENT_LIST_CSV = 'backofficeapi_whitelabel_get_payment_list_csv',
}

export enum PRESET_SETTINGS_PERMISSIONS {
  // вывод списка настроек cashier config
  GET_CONFIG_LIST = 'backofficeapi_get_cashier_config_list',
  // вывод конкретной настройки cashier config
  GET_CONFIG = 'backofficeapi_get_cashier_config',
  // обновление конкретной настройки cashier config
  UPDATE_CASHIER_CONFIG = 'backofficeapi_update_cashier_config',
  // создание новой настройки cashier config
  CREATE_CASHIER_CONFIG = 'backofficeapi_create_cashier_config',
}

export enum LOGS_PERMISSIONS {
  GET_BILLING_SETTINGS_CHANGE_HISTORY = 'backofficeapi_get_billing_provider_settings_change_history',
  GET_BILLING_SETTINGS_CHANGE_HISTORY_CSV = 'backofficeapi_get_billing_provider_settings_change_history_csv',
  GET_BILLING_SETTINGS_CHANGE_FILTER_LIST = 'backofficeapi_get_billing_provider_settings_change_filter_list',
}

export type PaymentsPermissions = {
  [PAYMENTS_SECTIONS_ROUTES.PS_MANAGEMENT]: { [key in PS_MANAGEMENT_PERMISSIONS]: boolean };
  [PAYMENTS_SECTIONS_ROUTES.TRANSACTIONS]: { [key in TRANSACTIONS_PERMISSIONS]: boolean };
  [PAYMENTS_SECTIONS_ROUTES.PRESET_SETTINGS]: { [key in PRESET_SETTINGS_PERMISSIONS]: boolean };
  [PAYMENTS_SECTIONS_ROUTES.LOGS]: { [key in LOGS_PERMISSIONS]: boolean };
};
