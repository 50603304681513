import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LogsPageParameterRow',

  props: {
    logsParameterRow: {
      type: Object as () => Record<string, string>,
      required: true,
    },
  },

  emits: ['clickParameterRow'],
});
