export const paginationProperties = {
  pageSizes: [5, 15, 25, 50, 100, 500, 1000],
  layout: 'total, sizes, prev, pager, next, jumper',
};

export const DEFAULT_LIMIT = 25;

export const DEFAULT_OFFSET = 0;

export const DEFAULT_PAYMENT_METHODS_LIST_LIMIT = 1000;

export const DEFAULT_ITEMS_LIMIT = 5;

export const DEFAULT_ITEMS_PER_PAGE = 25;

export const DEFAULT_ITEMS_PER_PAGE_LIST = [50, 100];

export const DEFAULT_REPORT_LIMIT = 1000;
