import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "preset-settings-page__currency-table__presets"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    size: "small",
    border: "",
    class: "preset-settings-page__currency-table",
    "span-method": _ctx.spanMethod,
    "cell-class-name": "preset-settings-page__currency-table__cell",
    "header-cell-class-name": "preset-settings-page__currency-table__header-cell",
    data: _ctx.currencySettingsList
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        prop: "empty",
        width: "100"
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currencySettingsColumns, (column) => {
        return (_openBlock(), _createBlock(_component_el_table_column, {
          key: column.value,
          prop: column.value,
          label: column.label,
          width: column.width
        }, {
          default: _withCtx(({ row }) => [
            (column.value === _ctx.CurrencyTableColumnProperties.DEFAULT_AMOUNT)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (row.defaultAmount)
                    ? (_openBlock(), _createBlock(_component_el_tag, {
                        key: 0,
                        type: "info"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(row.defaultAmount), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */))
                    : _createCommentVNode("v-if", true)
                ], 64 /* STABLE_FRAGMENT */))
              : (column.value === _ctx.CurrencyTableColumnProperties.ACTION)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(row.type.charAt(0).toUpperCase() + row.type.slice(1)), 1 /* TEXT */)
                  ], 64 /* STABLE_FRAGMENT */))
                : (column.value === _ctx.CurrencyTableColumnProperties.PRESET_AMOUNTS)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      (row.presetAmountList?.length)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.presetAmountList, (tag) => {
                              return (_openBlock(), _createBlock(_component_el_tag, {
                                key: tag,
                                type: "info"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(tag), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]))
                        : _createCommentVNode("v-if", true)
                    ], 64 /* STABLE_FRAGMENT */))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                      _createTextVNode(_toDisplayString(row[column.value]), 1 /* TEXT */)
                    ], 64 /* STABLE_FRAGMENT */))
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["prop", "label", "width"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["span-method", "data"]))
}