import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "logs-page-difference-dialog" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!
  const _component_LogsPageDifference = _resolveComponent("LogsPageDifference")!
  const _component_Right = _resolveComponent("Right")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, { width: "50%" }, {
    header: _withCtx(() => [
      _createVNode(_component_el_descriptions, {
        direction: "vertical",
        size: "small",
        column: 4,
        border: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_descriptions_item, { label: "Date" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.parameterItem.date), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_descriptions_item, { label: "Backoffice action" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.parameterItem.action), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_descriptions_item, { label: "Admin email" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.parameterItem.adminEmail), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_LogsPageDifference, {
          "current-parameter": _ctx.parameterItem.formerParameterList
        }, null, 8 /* PROPS */, ["current-parameter"]),
        _createVNode(_component_el_icon, null, {
          default: _withCtx(() => [
            _createVNode(_component_Right)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_LogsPageDifference, {
          "current-parameter": _ctx.parameterItem.currentParameterList
        }, null, 8 /* PROPS */, ["current-parameter"])
      ])
    ]),
    _: 1 /* STABLE */
  }))
}