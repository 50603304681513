import { defineComponent, ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useCurrencyListStore } from '@/store/dictionary/currency-list.store';
import { PresetSettingsButtonName } from '@/models/preset-settings/preset-settings.model';
import type { CurrencyItem } from '@/models/dictionary/currency-list.model';
import type { FormInstance } from 'element-plus';

export default defineComponent({
  name: 'AddCurrencyDialog',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    currencyList: {
      type: Array<CurrencyItem>,
      required: true,
    },
  },
  emits: ['update:modelValue', 'add-currency-list'],
  setup(_, { emit }) {
    const addCurrencyForm = ref<FormInstance>();

    const { isCurrencyListLoading } = storeToRefs(useCurrencyListStore());
    const { getCurrencyList } = useCurrencyListStore();

    const selectedCurrencies = ref<CurrencyItem[]>([]);

    const closeAddCurrencyDialog = (): void => {
      emit('update:modelValue', false);
    };

    const addCurrency = (): void => {
      if (!selectedCurrencies.value.length) {
        closeAddCurrencyDialog();

        return;
      }

      emit('add-currency-list', selectedCurrencies.value);
      selectedCurrencies.value = [];
      closeAddCurrencyDialog();
    };

    onMounted(() => {
      getCurrencyList();
    });

    return {
      addCurrencyForm,
      addCurrency,
      selectedCurrencies,
      isCurrencyListLoading,
      closeAddCurrencyDialog,
      PresetSettingsButtonName,
    };
  },
});
