import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "geo-payment-methods__drag-handle" }
const _hoisted_2 = {
  key: 0,
  class: "geo-payment-methods__logo"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "geo-payment-methods__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_PsLimitsDetails = _resolveComponent("PsLimitsDetails")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_el_table = _resolveComponent("el-table")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_el_table, {
    ref: "dragSortingTableRef",
    size: "small",
    class: "geo-payment-methods",
    data: _ctx.geoPaymentMethodList,
    "row-key": "billingPaymentMethodSettingsId",
    border: ""
  }, {
    default: _withCtx(() => [
      (_ctx.isGeoPaymentMethodFullListUpdateAllowed)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 0,
            label: "Sort",
            width: "45",
            align: "center"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_el_icon, { size: "22" }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icons.DCaret)))
                  ]),
                  _: 1 /* STABLE */
                })
              ])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_el_table_column, {
        type: "index",
        label: "#",
        align: "center",
        width: "35"
      }),
      (_ctx.isGetGeoPaymentMethodDepositLimitsAllowed && _ctx.isDepositColumnsDataVisible)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 1,
            type: "expand",
            width: "25",
            fixed: "left",
            "class-name": "geo-payment-methods__expand-column_disabled"
          }, {
            default: _withCtx(({ row }) => [
              _createVNode(_component_PsLimitsDetails, {
                "payment-method-settings-id": row.billingPaymentMethodSettingsId
              }, null, 8 /* PROPS */, ["payment-method-settings-id"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.geoPaymentMethodColumnsList, (column) => {
        return (_openBlock(), _createBlock(_component_el_table_column, {
          key: column.value,
          prop: column.value,
          label: column.label,
          align: column.align,
          width: column.width
        }, {
          default: _withCtx(({ row }) => [
            (column.value === _ctx.GeoPaymentMethodColumnProperties.IMAGE_URL)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("img", {
                    src: row.filePath,
                    class: "geo-payment-methods__image",
                    alt: "payment system logo"
                  }, null, 8 /* PROPS */, _hoisted_3)
                ]))
              : _createCommentVNode("v-if", true),
            (column.value === _ctx.GeoPaymentMethodColumnProperties.SEGMENT_LIST)
              ? (_openBlock(), _createBlock(_component_el_scrollbar, {
                  key: 1,
                  "max-height": "70",
                  always: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.segmentList, (segment) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: segment.segmentId
                      }, _toDisplayString(segment.name), 1 /* TEXT */))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              : _createCommentVNode("v-if", true),
            (column.value === _ctx.GeoPaymentMethodColumnProperties.EXCLUDED_SEGMENT_LIST)
              ? (_openBlock(), _createBlock(_component_el_scrollbar, {
                  key: 2,
                  "max-height": "70",
                  always: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.excludedSegmentList, (segment) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: segment.segmentId
                      }, _toDisplayString(segment.name), 1 /* TEXT */))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["prop", "label", "align", "width"]))
      }), 128 /* KEYED_FRAGMENT */)),
      (_ctx.isDepositColumnsDataVisible)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 2,
            label: "Deposit limit",
            width: "140"
          }, {
            default: _withCtx(({ row }) => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row[_ctx.GeoPaymentMethodColumnProperties.DEPOSIT_LIMIT_LIST], (limit) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: limit.currency
                }, _toDisplayString(limit.currency) + " - " + _toDisplayString(limit.min) + " → " + _toDisplayString(limit.max), 1 /* TEXT */))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.isWithdrawColumnsDataVisible)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 3,
            label: "Cash out limit",
            width: "140"
          }, {
            default: _withCtx(({ row }) => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row[_ctx.GeoPaymentMethodColumnProperties.WITHDRAW_LIMIT_LIST], (limit) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: limit.currency
                }, _toDisplayString(limit.currency) + " - " + _toDisplayString(limit.min) + " → " + _toDisplayString(limit.max), 1 /* TEXT */))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_el_table_column, {
        label: "Availability for organics",
        align: "center",
        width: "100"
      }, {
        default: _withCtx(({ row }) => [
          _createVNode(_component_el_text, {
            type: _ctx.getOrganicsAvailabilityData(row[_ctx.GeoPaymentMethodColumnProperties.ORGANICS_ENABLED]).type
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getOrganicsAvailabilityData(row[_ctx.GeoPaymentMethodColumnProperties.ORGANICS_ENABLED]).value), 1 /* TEXT */)
            ]),
            _: 2 /* DYNAMIC */
          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["type"])
        ]),
        _: 1 /* STABLE */
      }),
      (_ctx.isDepositColumnsDataVisible)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 4,
            label: "Deposit",
            align: "center",
            width: "70"
          }, {
            default: _withCtx(({ row }) => [
              _createVNode(_component_el_switch, {
                modelValue: row[_ctx.GeoPaymentMethodColumnProperties.DEPOSIT_ENABLED],
                "onUpdate:modelValue": ($event: any) => ((row[_ctx.GeoPaymentMethodColumnProperties.DEPOSIT_ENABLED]) = $event),
                disabled: _ctx.checkIsNewlyAddedGeoPaymentMethod(row) || !_ctx.isPartialUpdateGlobalPaymentMethodAllowed,
                onChange: ($event: any) => (_ctx.switchPaymentMethod(row, _ctx.GeoPaymentMethodColumnProperties.DEPOSIT_ENABLED))
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled", "onChange"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.isWithdrawColumnsDataVisible)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 5,
            label: "Cash out",
            align: "center",
            width: "70"
          }, {
            default: _withCtx(({ row }) => [
              _createVNode(_component_el_switch, {
                modelValue: row[_ctx.GeoPaymentMethodColumnProperties.WITHDRAW_ENABLED],
                "onUpdate:modelValue": ($event: any) => ((row[_ctx.GeoPaymentMethodColumnProperties.WITHDRAW_ENABLED]) = $event),
                disabled: _ctx.checkIsNewlyAddedGeoPaymentMethod(row) || !_ctx.isPartialUpdateGlobalPaymentMethodAllowed,
                onChange: ($event: any) => (_ctx.switchPaymentMethod(row, _ctx.GeoPaymentMethodColumnProperties.WITHDRAW_ENABLED))
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled", "onChange"])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      (_ctx.isActionsColumnVisible)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 6,
            label: "Actions",
            align: "center",
            width: "80"
          }, {
            default: _withCtx(({ row }) => [
              _createElementVNode("div", _hoisted_4, [
                (_ctx.isAllowedSettingButton(row))
                  ? (_openBlock(), _createBlock(_component_el_dropdown, {
                      key: 0,
                      trigger: "click"
                    }, {
                      dropdown: _withCtx(() => [
                        _createVNode(_component_el_dropdown_menu, null, {
                          default: _withCtx(() => [
                            (_ctx.isUpdateGeoPaymentMethodAllowed)
                              ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                  key: 0,
                                  onClick: ($event: any) => (_ctx.openGeoPaymentMethodSettings(row.billingPaymentMethodSettingsId))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" General settings ")
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                              : _createCommentVNode("v-if", true),
                            (_ctx.isUpdateGeoPaymentMethodDepositLimitsAllowed && _ctx.isDepositColumnsDataVisible)
                              ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                                  key: 1,
                                  onClick: ($event: any) => (_ctx.openPspLimitsSettings(row.billingPaymentMethodSettingsId))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" PSP deposit limits ")
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                              : _createCommentVNode("v-if", true)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_el_button, {
                          icon: _ctx.icons.Setting,
                          circle: "",
                          size: "small"
                        }, null, 8 /* PROPS */, ["icon"])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */))
                  : _createCommentVNode("v-if", true),
                (_ctx.isDeleteGeoPaymentMethodAllowed)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 1,
                      icon: _ctx.icons.Delete,
                      type: "danger",
                      circle: "",
                      size: "small",
                      onClick: ($event: any) => (_ctx.requestDeleteConfirmation(row))
                    }, null, 8 /* PROPS */, ["icon", "onClick"]))
                  : _createCommentVNode("v-if", true)
              ])
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["data"])), [
    [_directive_loading, _ctx.isGeoPaymentMethodListLoading]
  ])
}