import type { FormRules, FormInstance } from 'element-plus';
import { defineComponent, ref, PropType } from 'vue';
import { SettingsForm } from '@/models/preset-settings/settings-screen.model';

export default defineComponent({
  name: 'CurrencyForm',
  props: {
    settingsForm: {
      type: Object as PropType<SettingsForm>,
      required: true,
    },
    rules: {
      type: Object as PropType<FormRules<SettingsForm>>,
      required: true,
    },
    switchers: {
      type: Object as PropType<Record<string, boolean>>,
      required: true,
    },
  },

  emits: ['onChangeCurrencyForm'],

  setup() {
    const currencyFormRef = ref<FormInstance>();

    return {
      currencyFormRef,
    };
  },
});
