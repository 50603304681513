import { ApiResourceMethod } from '@/api/api-requests-factory';
import { BaseResponse } from '@/api/api-requests-factory/models/response.model';
import {
  LogsFilterList,
  LogsFilterListResponseModel,
} from '@/models/logs-page/logs-list.model';

export default class GetLogFilterListRequest extends ApiResourceMethod<LogsFilterListResponseModel> {
  public perform() {
    return this.performer.requestWithMapper(
      {},
      (payload: BaseResponse<LogsFilterList>) => {
        return { data: payload.data, headers: payload.headers };
      },
    );
  }
}
