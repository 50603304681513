import { ColumnFilterItem } from '@/models/column-filter/column-filter.model';

export const logsListColumnsStorageKey = 'LogsList_columns';

export type LogsItem = {
  geo: string | null;
  action: string;
  date: string;
  paymentSystem: string | null;
  adminEmail: string;
  adminClientIp: string | null;
  formerParameterList: string | null;
  currentParameterList: string | null;
}

export type EmailFilterItem = {
  value: string;
}

export type GetFilteredAdminEmailList = (
  query: string,
  callback: (results: EmailFilterItem[]) => void
) => void;

export type LogsFilterList = {
  adminEmailFilterList: EmailFilterItem[];
  actionFilterList: string[];
  paymentSystemFilterList: string[];
  geoFilterList: string[];
}

const enum LogsListColumnValues {
  DATE = 'date',
  GEO = 'geo',
  PAYMENT_SYSTEM = 'paymentSystem',
  ACTION = 'action',
  CURRENT_PARAMETER_LIST = 'currentParameterList',
  FORMER_PARAMETER_LIST = 'formerParameterList',
  ADMIN_ID = 'adminId',
  ADMIN_EMAIL = 'adminEmail',
  INITIATOR_IP = 'adminClientIp',
}

const enum LogsListColumnLabels {
  DATE = 'Date',
  GEO = 'GEO',
  PAYMENT_SYSTEM = 'Payment System',
  ACTION = 'Action',
  CURRENT_PARAMETER = 'Current parameter',
  ADMIN_EMAIL = 'Admin Email',
  INITIATOR_IP = 'Initiator IP',
  FORMER_PARAMETER = 'Former parameter',
}

export type LogsResponseModel = {
  data: LogsItem[];
  headers: Record<string, string>;
};

export type LogsFilterListResponseModel = {
  data: LogsFilterList;
  headers: Record<string, string>;
};

export type LogsFilterModel = {
  startDate?: string;
  endDate?: string;
  geo?: string;
  action?: string;
  paymentSystem?: string;
  adminEmail?: string;
}

export type LogListRequestModel = {
  limit?: number;
  sort?: string;
  filterList?: LogsFilterModel;
  prevPageToken?: string;
  nextPageToken?: string;
}

export const logsListColumns: ColumnFilterItem[] = [
  {
    label: LogsListColumnLabels.DATE,
    value: LogsListColumnValues.DATE,
    width: 150,
  },
  {
    label: LogsListColumnLabels.GEO,
    value: LogsListColumnValues.GEO,
    width: 135,
  },
  {
    label: LogsListColumnLabels.PAYMENT_SYSTEM,
    value: LogsListColumnValues.PAYMENT_SYSTEM,
    width: 135,
  },
  {
    label: LogsListColumnLabels.ACTION,
    value: LogsListColumnValues.ACTION,
    width: 200,
  },
  {
    label: LogsListColumnLabels.FORMER_PARAMETER,
    value: LogsListColumnValues.FORMER_PARAMETER_LIST,
    width: 200,
  },
  {
    label: LogsListColumnLabels.CURRENT_PARAMETER,
    value: LogsListColumnValues.CURRENT_PARAMETER_LIST,
    width: 200,
  },
  {
    label: LogsListColumnLabels.ADMIN_EMAIL,
    value: LogsListColumnValues.ADMIN_EMAIL,
    width: 200,
  },
  {
    label: LogsListColumnLabels.INITIATOR_IP,
    value: LogsListColumnValues.INITIATOR_IP,
    width: 200,
  },
];
