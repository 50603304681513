import { defineComponent, computed, ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { usePresetSettingsStore } from '@/store/preset-settings/preset-settings.store';
import { useCountryListStore } from '@/store/dictionary/countries.store';
import AddCountryDialog from '@/layouts/preset-settings-page/modals/add-country-dialog/add-country-dialog.vue';
import ConfigListTable from '@/layouts/preset-settings-page/config-list-table/config-list-table.vue';
import SettingsScreen from '@/layouts/preset-settings-page/settings-screen/settings-screen.vue';

export default defineComponent({
  name: 'PresetSettingsPage',
  components: {
    AddCountryDialog,
    ConfigListTable,
    SettingsScreen,
  },
  setup() {
    const { selectedCountryConfig } = storeToRefs(usePresetSettingsStore());
    const { getCountryList } = useCountryListStore();
    const isAddCountryDialogVisible = ref(false);
    const isSettingsScreenVisible = computed(() => Boolean(selectedCountryConfig.value.country));

    const openAddCountryDialog = (): void => {
      isAddCountryDialogVisible.value = true;
    };

    onMounted(() => {
      getCountryList();
    });

    return {
      openAddCountryDialog,
      isSettingsScreenVisible,
      isAddCountryDialogVisible,
    };
  },
});
