import { PAYMENTS_SECTIONS_ROUTES } from '@/models/app-internal/permissions.model';
import { RouteComponent, RouteRecordRaw } from 'vue-router';

export const logsRoutes: RouteRecordRaw[] = [
  {
    name: PAYMENTS_SECTIONS_ROUTES.LOGS,
    path: `/${PAYMENTS_SECTIONS_ROUTES.LOGS}`,
    component: async(): Promise<RouteComponent> =>
      await import('@/layouts/logs-page/logs-page.vue'),
  },
];
