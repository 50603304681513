import { storeToRefs } from 'pinia';
import { defineComponent } from 'vue';
import { Filter, Refresh, InfoFilled, Download } from '@element-plus/icons-vue';
import ColumnFilter from '@/components/column-filter/column-filter';
import {
  logsListColumns,

} from '@/models/logs-page/logs-list.model';
import TwoFactorAuthDialog from '@/components/two-factor-auth-dialog/two-factor-auth-dialog.vue';
import { useLogsListStore } from '@/store/logs-page/logs-list.store';
import LogsPageParameterRow from '@/layouts/logs-page/logs-page-parameter-row/logs-page-parameter-row.vue';
import LogsPageDifferenceDialog from '@/layouts/logs-page/logs-page-difference-dialog/logs-page-difference-dialog.vue';
import LogsPageFilters from '@/layouts/logs-page/logs-page-filters/logs-page-filters.vue';
import { usePermissionsStore } from '@/store/permissions.store';
import { useLogListReportStore } from '@/store/logs-page/log-list-report.store';

const icons = {
  Refresh,
  Filter,
  InfoFilled,
  Download,
};

export default defineComponent({
  name: 'LogsPage',

  components: {
    ColumnFilter,
    TwoFactorAuthDialog,
    LogsPageParameterRow,
    LogsPageDifferenceDialog,
    LogsPageFilters,
  },

  setup() {
    const logsListStore = useLogsListStore();

    const {
      getLogsList,
      getFilterOptions,
      setSelectedParameterItem,
      setDialogVisibility,
      handleSizeChange,
      handleCurrentChange,
    } = logsListStore;

    const {
      logsListWithFormattedDates,
      isLogsListLoading,
      selectedParameterItem,
      isDialogVisible,
      currentPage,
      totalPages,
      limit,
      pageSizes,
    } = storeToRefs(logsListStore);

    const { isLogsFiltersAllowed } = usePermissionsStore();

    const { getLogListReport } = useLogListReportStore();

    const isNeedShowList = (column: string): boolean =>
      ['formerParameterList', 'currentParameterList'].includes(column);

    const refreshData = () => {
      getLogsList();
    };

    const onShowDifference = (parameterItem) => {
      setSelectedParameterItem(parameterItem);

      setDialogVisibility(true);
    };

    getFilterOptions();

    getLogsList();

    return {
      handleCurrentChange,
      handleSizeChange,
      pageSizes,
      currentPage,
      totalPages,
      limit,
      getLogListReport,
      isLogsFiltersAllowed,
      isNeedShowList,
      onShowDifference,
      logsListColumns,
      selectedParameterItem,
      isDialogVisible,
      icons,
      logsListWithFormattedDates,
      isLogsListLoading,
      getLogsList,
      refreshData,
    };
  },
});
