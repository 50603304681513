import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "model-value": _ctx.modelValue,
    title: "New country",
    width: "400",
    "align-center": "",
    onClose: _ctx.closeAddCountryDialog
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_button, { onClick: _ctx.closeAddCountryDialog }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.isAvailableCountryList ? _ctx.PresetSettingsButtonName.CANCEL : _ctx.PresetSettingsButtonName.CLOSE), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]),
        (_ctx.isAvailableCountryList)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              type: "primary",
              loading: _ctx.isCreateCashierConfigLoading,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.addCountry(_ctx.addCountryForm)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.PresetSettingsButtonName.ADD), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["loading"]))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    default: _withCtx(() => [
      (_ctx.isAvailableCountryList)
        ? (_openBlock(), _createBlock(_component_el_form, {
            key: 0,
            ref: "addCountryForm",
            model: _ctx.modalForm,
            "label-width": "auto",
            class: "preset-settings-page__modal-form",
            "require-asterisk-position": "right",
            "label-position": "top"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "Country",
                prop: "country",
                rules: _ctx.presetSettingsValidationRules.country
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_select, {
                    modelValue: _ctx.modalForm.country,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalForm.country) = $event)),
                    placeholder: "Select country",
                    filterable: ""
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableCountryList, (item) => {
                        return (_openBlock(), _createBlock(_component_el_option, {
                          key: item.code,
                          label: item.name,
                          value: item.code
                        }, null, 8 /* PROPS */, ["label", "value"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["rules"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["model"]))
        : (_openBlock(), _createBlock(_component_el_text, {
            key: 1,
            size: "large"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" There are no available countries to add ")
            ]),
            _: 1 /* STABLE */
          }))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["model-value", "onClose"]))
}