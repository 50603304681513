import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_el_dialog, {
    "model-value": _ctx.modelValue,
    title: "Add currency",
    width: "600",
    "align-center": "",
    onClose: _ctx.closeAddCurrencyDialog
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_button, { onClick: _ctx.closeAddCurrencyDialog }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.PresetSettingsButtonName.CANCEL), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]),
        _createVNode(_component_el_button, {
          type: "primary",
          onClick: _ctx.addCurrency
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.PresetSettingsButtonName.ADD), 1 /* TEXT */)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "addCurrencyForm",
        "require-asterisk-position": "right",
        "label-position": "top",
        class: "preset-settings-page__modal-form"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: "Currency",
            prop: "currency"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_select, {
                modelValue: _ctx.selectedCurrencies,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCurrencies) = $event)),
                multiple: "",
                clearable: "",
                filterable: "",
                placeholder: "Select currencies"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currencyList, (currency) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: currency.code,
                      label: currency.code,
                      value: currency.code
                    }, null, 8 /* PROPS */, ["label", "value"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 512 /* NEED_PATCH */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["model-value", "onClose"])), [
    [_directive_loading, _ctx.isCurrencyListLoading]
  ])
}