import { Ref } from 'vue';
import { HttpMethods, RequestPerformer } from '@/api/api-requests-factory';
import GetLogListRequest from '@/api/logs-page/get-logs-list.request';
import GetLogFilterListRequest from '@/api/logs-page/get-logs-filter-options.request';
import GetLogListReportRequest from '@/api/logs-page/get-log-list-report-request';

export class LogsApiResources {
  constructor(
    private readonly requestPerformer: RequestPerformer,
    private readonly apiUrl: Ref<string>,
  ) { }

  public get getLogListRequest(): GetLogListRequest {
    return new GetLogListRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/billingProviderSettingsChangeHistory`,
      HttpMethods.GET,
    );
  }

  public get getLogFilterListRequest(): GetLogFilterListRequest {
    return new GetLogFilterListRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/billingProviderSettingsChangeFilterList`,
      HttpMethods.GET,
    );
  }

  public get getLogListReportRequest(): GetLogListReportRequest {
    return new GetLogListReportRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/billingProviderSettingsChangeHistory/csv`,
      HttpMethods.GET,
    );
  }
}
