import { defineComponent } from 'vue';
import { formatToJsonString } from '@/utils/formated-json';

export default defineComponent({
  name: 'LogsPageDifference',

  props: {
    currentParameter: {
      type: Object as () => Record<string, string>,
      required: true,
    },
  },

  setup() {
    return {
      formatToJsonString,
    };
  },

});
