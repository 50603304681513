import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    "label-position": "top",
    class: "logs-filter"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form_item, {
        label: "Start date",
        prop: "updatedAtFrom",
        class: "logs-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_date_picker, {
            modelValue: _ctx.filterFormData.startDate,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterFormData.startDate) = $event)),
            type: "datetime",
            placeholder: "Select date and time"
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "End date",
        prop: "updatedAtFrom",
        class: "logs-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_date_picker, {
            modelValue: _ctx.filterFormData.endDate,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterFormData.endDate) = $event)),
            type: "datetime",
            placeholder: "Select date and time"
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Action",
        prop: "action",
        class: "logs-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.filterFormData.action,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filterFormData.action) = $event)),
            placeholder: "Select action",
            filterable: "",
            clearable: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logsFilterList.actionFilterList, (action) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: action,
                  label: action,
                  value: action
                }, null, 8 /* PROPS */, ["label", "value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Payment system",
        prop: "paymentSystem",
        class: "logs-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.filterFormData.paymentSystem,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filterFormData.paymentSystem) = $event)),
            placeholder: "Select payment system",
            filterable: "",
            clearable: "",
            "fit-input-width": ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logsFilterList.paymentSystemFilterList, (paymentSystem) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: paymentSystem,
                  label: paymentSystem,
                  value: paymentSystem
                }, null, 8 /* PROPS */, ["label", "value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Geo",
        prop: "geo",
        class: "logs-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_select, {
            modelValue: _ctx.filterFormData.geo,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.filterFormData.geo) = $event)),
            placeholder: "Select geo",
            filterable: "",
            clearable: "",
            "fit-input-width": ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logsFilterList.geoFilterList, (paymentSystem) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: paymentSystem,
                  label: paymentSystem,
                  value: paymentSystem
                }, null, 8 /* PROPS */, ["label", "value"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_form_item, {
        label: "Admin email",
        prop: "adminEmail",
        class: "logs-filter__field"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_autocomplete, {
            modelValue: _ctx.filterFormData.adminEmail,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.filterFormData.adminEmail) = $event)),
            "fetch-suggestions": _ctx.getFilteredAdminEmailList,
            class: "logs-filter__field",
            placeholder: "Select admin email",
            clearable: ""
          }, null, 8 /* PROPS */, ["modelValue", "fetch-suggestions"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_el_button, {
        circle: "",
        icon: _ctx.icons.Filter,
        loading: _ctx.isLogsListLoading,
        onClick: _ctx.submitFilter
      }, null, 8 /* PROPS */, ["icon", "loading", "onClick"]),
      _createVNode(_component_el_button, {
        icon: _ctx.icons.Refresh,
        loading: _ctx.isLogsListLoading,
        onClick: _ctx.resetFilter
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Refresh ")
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["icon", "loading", "onClick"]),
      (_ctx.isLogsDownloadCvsAllowed)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            loading: _ctx.isLogListReportLoading,
            icon: _ctx.icons.Download,
            type: "primary",
            size: "medium",
            plain: "",
            onClick: _ctx.openTwoFactorDialog
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Report ")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["loading", "icon", "onClick"]))
        : _createCommentVNode("v-if", true)
    ]),
    _: 1 /* STABLE */
  }))
}