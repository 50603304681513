import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsScreen = _resolveComponent("SettingsScreen")!
  const _component_ConfigListTable = _resolveComponent("ConfigListTable")!
  const _component_AddCountryDialog = _resolveComponent("AddCountryDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isSettingsScreenVisible)
      ? (_openBlock(), _createBlock(_component_SettingsScreen, { key: 0 }))
      : (_openBlock(), _createBlock(_component_ConfigListTable, {
          key: 1,
          onShowAddCountryDialog: _ctx.openAddCountryDialog
        }, null, 8 /* PROPS */, ["onShowAddCountryDialog"])),
    _createVNode(_component_AddCountryDialog, {
      modelValue: _ctx.isAddCountryDialogVisible,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isAddCountryDialogVisible) = $event))
    }, null, 8 /* PROPS */, ["modelValue"])
  ], 64 /* STABLE_FRAGMENT */))
}