import { LimitItem, LimitType } from '@/models/ps-management/limits.model';
import { PropType, computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'LimitsCell',
  props: {
    popoverTitle: {
      type: String,
      required: true,
    },
    limits: {
      type: Array as PropType<LimitItem[]>,
      required: true,
    },
    currency: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const getLimitTitle = (limit: LimitItem): string => {
      if (!limit) {
        return '';
      }

      const { limitType, limitValue } = limit;

      const limitTitleMapping = {
        [LimitType.NUMBER]: `# ${String(limit.limitValue)}`,
        [LimitType.AMOUNT]: `∑ ${String(limit.limitValue)} ${props.currency}`,
      };

      return limitTitleMapping[limitType] ?? limitValue?.toString();
    };

    const isDetailsVisible = computed(() => {
      return (props.limits.length > 1 && props.limits[0]) ||
      (props.limits[0] && props.limits[0].segmentList.length > 1);
    });

    return {
      getLimitTitle,
      isDetailsVisible,
    };
  },
});
