import type { ColumnFilterItem } from '@/models/column-filter/column-filter.model';
import type { CashierConfig } from '@/models/preset-settings/preset-settings.model';
import type { TableColumnCtx } from 'element-plus';

export type CashierActionType = 'deposit' | 'withdrawal';
export interface CurrencySettings {
  currency: string;
  type: CashierActionType;
  defaultAmount?: number | null;
  presetAmountList?: number[];
}

export enum CurrencyTableColumnProperties {
  CURRENCY = 'currency',
  ACTION = 'type',
  DEFAULT_AMOUNT = 'defaultAmount',
  PRESET_AMOUNTS = 'presetAmountList',
}

export enum CurrencyTableColumnLabels {
  CURRENCY = 'Currency',
  ACTION = 'Action',
  DEFAULT_AMOUNT = 'Default amount',
  PRESET_AMOUNTS = 'Widget values',
}

export interface InnerTableRowMethodProps {
  row: CashierConfig;
  column: TableColumnCtx<CashierConfig>;
  rowIndex: number;
  columnIndex: number;
}

export const currencySettingsColumns: ColumnFilterItem[] = [
  {
    label: CurrencyTableColumnLabels.CURRENCY,
    value: CurrencyTableColumnProperties.CURRENCY,
    width: 150,
  },
  {
    label: CurrencyTableColumnLabels.ACTION,
    value: CurrencyTableColumnProperties.ACTION,
    width: 150,
  },
  {
    label: CurrencyTableColumnLabels.DEFAULT_AMOUNT,
    value: CurrencyTableColumnProperties.DEFAULT_AMOUNT,
    width: 200,
  },
  {
    label: CurrencyTableColumnLabels.PRESET_AMOUNTS,
    value: CurrencyTableColumnProperties.PRESET_AMOUNTS,
  },
];
