import { RequestPerformer } from '@/api/api-requests-factory';
import { useEnvStore } from '@/store/env.store';
import { storeToRefs } from 'pinia';
import { DictionaryApiResources } from '@/api/dictionary';
import { PsManagementApiResources } from '@/api/ps-management';
import { FileApiResources } from '@/api/file';
import { TransactionsApiResources } from '@/api/transactions';
import { PresetSettingsApiResources } from '@/api/preset-settings';
import { LogsApiResources } from '@/api/logs-page';
import { AuthApiResources } from '@/api/auth';

export interface ApiResources {
  file: FileApiResources;
  dictionary: DictionaryApiResources;
  psManagement: PsManagementApiResources;
  transactions: TransactionsApiResources;
  logs: LogsApiResources;
  auth: AuthApiResources;
  presetSettings: PresetSettingsApiResources;
}

export class ApiResourcesFactory {
  public getInstance(basePerformer: RequestPerformer): ApiResources {
    const { BACKOFFICE_API_URL } = storeToRefs(useEnvStore());

    return {
      file: new FileApiResources(basePerformer, BACKOFFICE_API_URL),
      dictionary: new DictionaryApiResources(basePerformer, BACKOFFICE_API_URL),
      psManagement: new PsManagementApiResources(basePerformer, BACKOFFICE_API_URL),
      transactions: new TransactionsApiResources(basePerformer, BACKOFFICE_API_URL),
      logs: new LogsApiResources(basePerformer, BACKOFFICE_API_URL),
      auth: new AuthApiResources(basePerformer, BACKOFFICE_API_URL),
      presetSettings: new PresetSettingsApiResources(basePerformer, BACKOFFICE_API_URL),
    };
  }
}
