import type { Ref } from 'vue';
import { HttpMethods, RequestPerformer } from '@/api/api-requests-factory';
import GetCashierConfigListRequest from '@/api/preset-settings/get-cashier-config-list.request';
import CreateCashierConfig from '@/api/preset-settings/create-cashier-config.request';
import GetCashierConfigRequest from '@/api/preset-settings/get-cashier-config.request';
import UpdateCashierConfig from '@/api/preset-settings/update-cashier-config.request';

export class PresetSettingsApiResources {
  constructor(
    private readonly requestPerformer: RequestPerformer,
    private readonly apiUrl: Ref<string>,
  ) { }

  public get getCashierConfigListRequest(): GetCashierConfigListRequest {
    return new GetCashierConfigListRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/cashierConfig`,
      HttpMethods.GET,
    );
  }

  public get createCashierConfig(): CreateCashierConfig {
    return new CreateCashierConfig(
      this.requestPerformer,
      `${this.apiUrl.value}/cashierConfig`,
      HttpMethods.POST,
    );
  }

  public get getCashierConfigRequest(): GetCashierConfigRequest {
    return new GetCashierConfigRequest(
      this.requestPerformer,
      `${this.apiUrl.value}/cashierConfig`,
      HttpMethods.GET,
    );
  }

  public get updateCashierConfig(): UpdateCashierConfig {
    return new UpdateCashierConfig(
      this.requestPerformer,
      `${this.apiUrl.value}/cashierConfig`,
      HttpMethods.PUT,
    );
  }
}
