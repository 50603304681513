import { ColumnFilterItem } from '@/models/column-filter/column-filter.model';

export type CashierConfig = {
  country: string;
  defaultAmount: {
    depositList: CashierConfigDefaultAmountItem[];
    withdrawalList: CashierConfigDefaultAmountItem[];
  } | null;
  suggestedAmount: {
    depositList: CashierConfigSuggestedAmountItem[];
    withdrawalList: CashierConfigSuggestedAmountItem[];
    defaultList: number[];
  } | null;
}

export type CashierConfigDefaultAmountItem = {
  currency: string;
  amount: number;
}

export type CashierConfigSuggestedAmountItem = {
  currency: string;
  presetAmountList: number[];
}

export enum PresetSettingsColumnProperties {
  EXPAND = 'expand',
  ID = 'id',
  COUNTRY = 'country',
  CURRENCY = 'currencyList',
  ACTIONS = 'actions',
}

export enum PresetSettingsColumnLabels {
  EXPAND = '',
  ID = '#',
  COUNTRY = 'Country',
  CURRENCY = 'Currency',
  ACTIONS = 'Actions',
}

export const cashierConfigListColumns: ColumnFilterItem[] = [
  {
    label: PresetSettingsColumnLabels.EXPAND,
    value: PresetSettingsColumnProperties.EXPAND,
    width: 50,
  },
  {
    label: PresetSettingsColumnLabels.ID,
    value: PresetSettingsColumnProperties.ID,
    width: 50,
    align: 'center',
  },
  {
    label: PresetSettingsColumnLabels.COUNTRY,
    value: PresetSettingsColumnProperties.COUNTRY,
    width: 150,
  },
  {
    label: PresetSettingsColumnLabels.CURRENCY,
    value: PresetSettingsColumnProperties.CURRENCY,
  },
  {
    label: PresetSettingsColumnLabels.ACTIONS,
    value: PresetSettingsColumnProperties.ACTIONS,
    width: 75,
    align: 'center',
  },
];

export enum PresetSettingsButtonName {
  CREATE = 'Create',
  ADD = 'Add',
  CANCEL = 'Cancel',
  CLOSE = 'Close',
  SAVE = 'Save',
  ADD_CURRENCY = 'Add currency',
  ADD_COUNTRY = 'Add country',
}
